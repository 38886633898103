@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Manrope", sans-serif !important ; */
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif !important;
}

:root {
  --font-family: "Inter", system-ui;
  --blue-btn: #467fff;

  --dark-blue-color: #0c1460;
  --header-font-size: 40px;
  --light-blue-color: #4d5178;
  --backend-background-color: #f9fbfc;
  --success-green-color: rgb(29, 196, 121);
  --black-color: #202020;
  --light-black-color: #d8d8d8;
}

.PhoneInputInput::placeholder {
  color: var(--light-blue-color);
  font-family: var(--font-family);
}

.PhoneInput {
  border-radius: 4px;
  height: 100%;
  margin-bottom: 0.2rem;
}

.PhoneInput:visited {
  border-color: black;
}

.PhoneInputInput {
  font-family: var(--font-family);
}

.PhoneInputInput:focus {
  background-color: #fff;
}

.PhoneInputInput:focus + .PhoneInput {
  border-color: #467fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.graphs-for-reporting .apexcharts-title-text {
  font-size: 20px !important;
  margin-bottom: 100px !important;
  padding-bottom: 100px !important;
  padding: 10px;
  font-weight: 600;
}
.graphs-for-reporting > div {
  padding :20px;
  border-radius :4px;
  background-color :white
}

