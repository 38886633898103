.SingleDatePicker {
  width: 100%;

  .SingleDatePickerInput {
    width: 100%;
    border: 0;

    .DateInput {
      width: 100%;

      .DateInput_input__focused {
        border: 0;
      }

      input {
		font-weight: 400 !important;
		color: #000000;
        background-color: #f5f8fa;
        width: 100%;
        border-radius: 5px;
        padding: 0.75rem 1rem;
        font-size: 1.1rem;
        line-height: 1.5;
        border: 0;
      }
    }
  }
}
