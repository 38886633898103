//
// Input placeholder color
//

@mixin placeholder($color) {
  // Chrome, Firefox, Opera, Safari 10.1+
  &:focus::placeholder {
    color: $color;
  }

  // Firefox
  &:focus::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
}
