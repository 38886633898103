.PhoneInputInput {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  background-clip: padding-box;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.475rem;
  background-color: #f5f8fa;
  color: #5e6278;
  transition: color 0.2s ease, background-color 0.2s ease;
  box-shadow: none !important;
  border:0;
}

.PhoneInputInput:focus {
  background-color: #eef3f7;
  border-color: #eef3f7;
  color: #5e6278;
  transition: color 0.2s ease, background-color 0.2s ease;
  outline: 0;
}


.PhoneInputCountry {
  background-color: #f5f8fa;
  padding: 0 5px;
  border-radius: 7px;
}