//
// Layout Config
//

// Root font Sizes
$root-font-size: 13px; // Root font size for desktop mode
$root-font-size-lg: 13px; // Root font size for tablet mode
$root-font-size-md: 12px; // Root font size for mobile mode

// Page background Color
$page-bg: if(isDarkMode(), #151521, #f3f6f9) !default;

// Content Spacing
$content-spacing: (
  desktop: 110px,
  // Padding for desktop mode
  tablet-and-mobile: 15px // Padding for tablet and mobile modes,,,,,
) !default;

// Header
$header-config: (
  // Desktop Mode
  desktop:
    (
      // Default Mode
      default:
        (
          height: 100px,
        ),
      // Fixed Mode
      fixed:
        (
          height: 65px,
          z-index: 97,
          bg-color: $body-bg,
          box-shadow:
            if(isDarkMode(), none, 0px 10px 30px 0px rgba(82, 63, 105, 0.08)),
        )
    ),
  // Tablet & Mobile Modes
  tablet-and-mobile:
    (
      // Default Mode
      default:
        (
          height: 60px,
        ),
      // Fixed Mode
      fixed:
        (
          z-index: 97,
          height: 55px,
          bg-color: $body-bg,
          box-shadow:
            if(isDarkMode(), none, 0px 10px 30px 0px rgba(82, 63, 105, 0.08)),
        )
    )
) !default;

// Aside
$aside-config: (
  bg-color: $body-bg,
  width: (
    desktop: 425px,
    tablet-and-mobile: 260px,
  ),
  primary-width: (
    desktop: 100px,
    tablet-and-mobile: 70px,
  ),
  z-index: 98,
  // Aside's z-index property
  transition-speed: 0.3s,
  // transition speed
  box-shadow:
    if(isDarkMode(), none, 0px 0px 80px 50px rgba(206, 213, 225, 0.12)),
) !default;
