.loading-page_body {
  min-height: 100vh;
}

.plane-icon-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  animation: rotating 2s linear infinite;
  animation-delay: -1s;
}

.plane-spinner {
  position: absolute;
  top: 37px;
  left: 77px;
  font-size: 3rem;
  transform: rotate(100deg);
  color: var(--dark-blue-color);
}

.loader {
  position: relative;
  width: 90px;
  height: 90px;
}

.loader span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* transform: rotate(calc(18deg * var(--i))); */
}

.loader span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background: var(--dark-blue-color);
  border-radius: 50%;
  animation: circle 2s linear infinite;
  /* animation-delay: ; */
}

.loader span:nth-child(1)::before {
  animation-delay: 0.1s;
}
.loader span:nth-child(2)::before {
  animation-delay: 0.2s;
}
.loader span:nth-child(3)::before {
  animation-delay: 0.3s;
}
.loader span:nth-child(4)::before {
  animation-delay: 0.4s;
}
.loader span:nth-child(5)::before {
  animation-delay: 0.5s;
}
.loader span:nth-child(6)::before {
  animation-delay: 0.6s;
}
.loader span:nth-child(7)::before {
  animation-delay: 0.7s;
}
.loader span:nth-child(8)::before {
  animation-delay: 0.8s;
}
.loader span:nth-child(9)::before {
  animation-delay: 0.9s;
}
.loader span:nth-child(10)::before {
  animation-delay: 1s;
}
.loader span:nth-child(11)::before {
  animation-delay: 1.1s;
}
.loader span:nth-child(12)::before {
  animation-delay: 1.2s;
}
.loader span:nth-child(13)::before {
  animation-delay: 1.3s;
}
.loader span:nth-child(14)::before {
  animation-delay: 1.4s;
}
.loader span:nth-child(15)::before {
  animation-delay: 1.5s;
}
.loader span:nth-child(16)::before {
  animation-delay: 1.6s;
}
.loader span:nth-child(17)::before {
  animation-delay: 1.7s;
}
.loader span:nth-child(18)::before {
  animation-delay: 1.8s;
}
.loader span:nth-child(19)::before {
  animation-delay: 1.9s;
}
.loader span:nth-child(20)::before {
  animation-delay: 2s;
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(370deg);
  }
}

@keyframes circle {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(1.2);
  }
  80%,
  100% {
    transform: scale(0);
  }
}
