//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .content {
    padding: 0;

  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .content {
    //padding: get($content-spacing, tablet-and-mobile) 0;
    padding-left: 10px;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
